<!-- View to create/modify layouts for ad planners -->
<template>
  <v-card class="ma-0 pa-0" fluid>
    <v-container class="ma-0 pa-0" fluid v-resize="onResize">
      <v-data-table :headers="headers" :items.sync="layouts" :height="tableHeight"
        no-data-text="No Layouts to Display" :loading="loading" :class="table" :search="search" fixed-header dense
        @click:row="editLayout">

        <template #top>
          <v-toolbar color="fill" flat>
            <v-row dense align="center">
              <v-col md="4" lg="2" xl="2">
                <v-card-title class="accent--text font-weight-normal">Layout Manager</v-card-title>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template #[`item.audit.created_on`]="{ item }">
          {{item.audit.created_on ? formatDateDisplay(item.audit.created_on) : ''}}
        </template>

        <template #[`item.audit.updated_on`]="{ item }">
          {{item.audit.updated_on ? formatDateDisplay(item.audit.updated_on) : ''}}
        </template>

        <template #[`item.features`]="{ item }">
          {{getFeatureLength(item)}}
        </template>

      </v-data-table>

      <Component :is="component" :key="componentKey" :value.sync="modal" :selectedLayout='selectedLayout' :defaultParty="defaultParty" @refresh="getLayouts" />

    </v-container>

    <v-fab-transition>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn v-on="on" :style="floatingButton" absolute bottom right fab class="primary"
            @click="component='LayoutBuilder', modal=true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Layout</span>
      </v-tooltip>
    </v-fab-transition>

  </v-card>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { tableConfig } from '@/mixins/table-config'
import {format} from '@/mixins/format'
// import partyList from '@/assets/parties.json'
// import prodPartyList from '@/assets/prod-parties.json'

export default {
  name: 'TemplateList',
  
  components: {
    LayoutBuilder: () => import('@/components/layouts/layout-builder'),
    // FeatureList: () => import('@/components/features/feature-list'),
    LayoutDelete: () => import('@/components/layouts/layout-delete')
  },

  mixins: [displayHelpers, tableConfig, format],
  
  data: () => ({
    component: '',
    componentKey: 0,
    modal: false,
    search: '',
    selectedParty: {},
    defaultParty: {},
    selectedLayout: {},
    layouts: [],
    parties: []
  }),

  computed: {
    headers () {
      return [
        { sortable: false, filterable: false, class: 'accent white--text' },
        { text: 'Layout Name', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_name' },
        { text: 'Created By', sortable: true, filterable: true, class: 'accent white--text', value: 'audit.created_by' },
        { text: 'Created On', sortable: true, filterable: true, class: 'accent white--text', value: 'audit.created_on' },
        { text: 'Updated By', sortable: true, filterable: true, class: 'accent white--text', value: 'audit.updated_by' },
        { text: 'Updated On', sortable: true, filterable: true, class: 'accent white--text', value: 'audit.updated_on' }
      ]
    }
  },

  watch: {
    defaultParty: {
      handler () {
        if (!this._.isEmpty(this.defaultParty)) {
          localStorage.setItem('layouts_party', JSON.stringify(this.defaultParty))
          this.getLayouts()
        }
      }
    },

    modal: {
      immediate: true,
      handler () {
        this.componentKey += 1
        if (!this.modal) this.selectedTemplate = {}
        this.getLayouts()
      }
    }
  },

  created () {
    this.tableHeight = window.innerHeight - 220
    this.getParties()
    // this.getTemplates()
  },

  methods: {
    onResize () {
      this.tableHeight =  window.innerHeight - 220
    },

    async getLayouts () {
      await this.$Layouts.getLayouts()
        .then(res =>{
          this.layouts = res.data
        }).catch(() => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get layouts` })
        })
    },
    async createLayout(layout) {
      await this.$Layouts.createLayout(layout)
        .then(() => {
           this.$store.dispatch('setSnackbar', { status: 'success', text: `Layout Created Successfully` })
        }).catch(() => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Create Layout` })
        })
    },

    editLayout (layout) {
      this.selectedLayout = layout
      this.component = 'LayoutBuilder'
      this.modal = true
    },

    getFeatureLength(layout) {
      let length = 0
      layout.features ? length === layout.features.length : length === 0
      return length
    }
  }
}
</script>
